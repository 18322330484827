// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import { CreateChatRoom, GetProjectLists, SendProjectInfo, SendProjectMedia } from "../../services/srvc-chats-realm";





export default function ChatActionModule (props) {

  const navigate = useNavigate();
  const asset = GetLocalUser()
  const team = GetLocalBusiness()
  // console.log(asset)
  
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState()
  const [memo, setMemo] = useState('')
  const [action, setAction] = useState(null)
  const [project, setProject] = useState([])
  const [selectedproject, setSelectedProject] = useState(null)
  const [medialist, setMediaList] = useState([])
  const [media, setMedia] = useState(null)

  const list = [
    {name:'Send Project Information',code:'info', memo:'Send information related with project', link:``, actv: true},
    {name:'Send Project Media', code:'media', memo:'Send Images and PDF related To Project', link:``, actv: true}
]

useEffect(()=>{
    setMemo('')
},[props.room])

useEffect(()=>{

    const fetchdata = async()=>{
        var res = await GetProjectLists({data:{}})
        // console.log(res)
        if(res.stat) setProject(res.data.list)
    }
    fetchdata()

},[])

const handleClick= async(item)=>{
    // console.log(item)
    if(props?.room)
    {
      setAction(item)
    }
    else{
        setMemo('Please Select User Whome You Want To send ')
    }
}

useEffect(()=>{
    if(selectedproject){
        var obj = project.find(item=> item.item == selectedproject)
        setMediaList([...obj.docs, ...obj.imag])
    }

},[selectedproject])

const handleMediaChoose = async(list)=>{
    var obj = medialist.find(item => item.item == list)
    setMedia(obj)
}

const ProjectMediaSend = async()=>{
    setMemo('Please Wait...')
    var user2 = props.room.user.find(item=> item.item!= asset.item)
    // console.log(user2)
     var datx = {
         room: props.room.item,
         media: media ,
         template:'',
         sender: {name:asset.name, mail:asset.mail, item: asset.item, mmid: asset.mobile},
         receiver: {name:user2.name, mail:user2.mail, item:user2.item,mmid:user2.mmid},
         text: '',
         mode:'text'
     }
    // console.log(datx)
    var res = await SendProjectMedia({data:datx, srvc:'******'})
    if(res.stat){ setMemo('Message Sent')}
    else {setMemo('Error')}
}

const SendProjectInformation = async()=>{
    setMemo('Please Wait...')
    var pro = project.find(item=> item.item == selectedproject)
    var user2 = props.room.user.find(item=> item.item!= asset.item)
    var datx ={
        room: props.room.item,
        template:'',
        sender: {name:asset.name, mail:asset.mail, item: asset.item, mobile: asset.mobile},
        receiver: {name:user2.name, mail:user2.mail, item:user2.item,mobile:user2.mmid},
        text: pro.text,
        mode:'text'
    }
    // console.log(datx)
    var res = await SendProjectInfo({data:datx})
    if(res.stat){ setMemo('Message Sent')}
    else {setMemo('Error')}
}


    


  return (
  <>

   <div className="">
    <div className="row" >
        <div className="p-2">  
            {
                list.map(item=>{
                    return(
                        <>
                            <div  onClick={()=> handleClick(item)} className={item.actv ? " border mb-2 rounded bg-white p-2 text-small d-flex cursor text-dark ":'d-none'}>
                                <div className= {item?.code == action?.code ?'me-auto text-primary':''  } >
                                    <h6 className="m-0 " style={{fontSize:'14px'}}>{item.name}</h6>
                                    <p className="text-secondary">{item.memo}</p>
                                </div>
                                <div className="text-end">
                                <i class='bx bx-chevrons-right mt-2 text-primary' style={{fontSize:'26px'}}></i>
                                </div>
                            </div>
                           
                        </>
                    )
                })
            } 


        </div>
        <p className="text-small">{memo}</p>
        
        <div className={action!= null && action.code == "info" ? '':'d-none'}>
            <div className="p-0">
                <label className="text-small">Choose Project</label>
                <select onChange={(e)=>setSelectedProject(e.target.value)} className="form-select text-small">
                    <option>Select</option>
                    {
                        project && project.map(item=>{
                            return(
                                <option className="text-small" value={item.item}>{item?.name || ''}</option>
                            )
                        })
                    }
                </select>
                <button onClick={()=>SendProjectInformation()} className="btn btn-primary rounded-xx text-small mt-3" style={{width:'auto'}}>Send Project Info</button>

            </div>

        </div>

        <div className={action!= null && action.code == "media" ? '':'d-none'}>
            <div className="p-0">
                <label className="text-small">Choose Project</label>
                <select onChange={(e)=>setSelectedProject(e.target.value)} className="form-select text-small">
                   <option>Select</option> 
                    {
                        project && project.map(item=>{
                            return(
                                <option className="text-small" value={item.item}>{item?.name || ''}</option>
                            )
                        })
                    }
                </select>


                <label className="text-small mt-2">Choose Media</label>
                <select onChange={(e)=>handleMediaChoose(e.target.value)} className="form-select text-small">
                    <option>Select</option> 
                    {
                        medialist && medialist.map(item=>{
                            return(
                                <option className="text-small" value={item.item}>{item?.name || ''}</option>
                            )
                        })
                    }
                </select>
                <button onClick={()=>ProjectMediaSend()} className="btn btn-primary rounded-xx text-small mt-3" style={{width:'auto'}}>Send Media</button>

            </div>

        </div>

        

    </div>

    
       

   </div> 

    
  </>

  )
}