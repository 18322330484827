//main
import { useState } from "react";

import ContentFormatXA from "../content/webz/content-format-xv";


import WebbDividerMedium from "../content/webx/webb-divider-md";
import HeaderSectionLite from "../content/webx/head-section-lite";
import HeaderSectionDark from "../content/webx/head-section-dark";
import WebbDateTime from "../content/webx/webb-datetime";

import { useEffect } from "react";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-local";
import { useNavigate } from "react-router-dom";
import ChatRoomListModule from "../content/chat/chat-rooms";
import RoomMessageModule from "../content/chat/room-messages";
import InputModule from "../content/chat/input";
import TemplateListModule from "../content/chat/template-list";
import ChatActionModule from "../content/chat/chat-action";

// import { GetUserContactsList } from "../services/srvc-contacts-realm";

// import { GetMyRooms, GetRoomNotification, RoomCreateForContact } from "../services/srvc-chat-realm";





export default function ChatView() {

  const asset = GetLocalUser() 

  const [sort, setSort] = useState('contracts');
//const [search, setSearch] = useState();
  const [data, setData] = useState([])
  const [selected, setSelected] = useState({})
  const [srch, setSearch] = useState('')
  const [room ,setRoom] = useState()
  const [contact, setContact] = useState([])

  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [roomselected, setRoomSelected] = useState(null)
  const history = useNavigate()

  
  const handleRoomSelect = async(item)=>{
    // console.log(item)
    setRoomSelected(item)
    setRefresh(!refresh)
  }

  const handleRefresh = async(item)=>{
    setRefresh(!refresh)
  }





  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-tone">Contacts</strong> </>,
      data: 
        <> 
          <div className="container-fluid">

          </div>
          <HeaderSectionLite data={{name: 'Search Contacts'}}/>
          <ChatRoomListModule selectroom = {handleRoomSelect} handleRefresh = {handleRefresh}   />

          
          
        </>,
      foot: <> <WebbDateTime /> </>,
    }}
    main=   
      {{      
        head:   roomselected ? roomselected.user[0].item != asset.item? roomselected.user[0].name : roomselected.user[1].name :'' || ''     || 'Chat List',
        data: 
          <> 
            {/* navs */}
            <RoomMessageModule  room={roomselected} refresh = {refresh} />
            <InputModule room={roomselected} />
            {/* <WebbDividerMedium />
            <WebbDividerMedium /> */}
          </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: 'Activity',
        data: 
        <div >
          <div >
            
            <ChatActionModule handleRefresh = {handleRefresh} room={roomselected} />
            {/* <TemplateListModule handleRefresh = {handleRefresh} room={roomselected}  /> */}
            <WebbDividerMedium/> 
          </div>
        </div>,
        foot: <> </>,
      }}  
    />
  </>
    
  )
}







