//main
import { useState } from "react";

import ContentFormatXA from "../content/webz/content-format-xv";


import WebbDividerMedium from "../content/webx/webb-divider-md";
import HeaderSectionLite from "../content/webx/head-section-lite";
import HeaderSectionDark from "../content/webx/head-section-dark";
import WebbDateTime from "../content/webx/webb-datetime";

import { useEffect } from "react";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-local";
import { useNavigate } from "react-router-dom";
import TopicListModule from "../content/querry/topic-list";
import QuerryMessageListModule from "../content/querry/querry-messages";
import InputModule from "../content/querry/input";
import QuerryActionModule from "../content/querry/querry-action";



export default function QuerryHomeView() {

  const asset = GetLocalUser() 
  const navigate = useNavigate()

  const [topic, setTopic] = useState(null)
  const [wait, setWait] = useState(false)
  const [ques, setQues] = useState(null)
  const [refresh, setRefresh] = useState(false)


  const handleTopicSelect = async(item)=>{
    //console.log(item)
    setTopic(item)
  }

  const handleWait = async(load,item)=>{
    setWait(load)
    setQues(item)
  }

  const handleRefresh = async(item)=>{
    setRefresh(!refresh)
  }




  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-tone">Ask</strong> </>,
      data: 
        <> 

          <HeaderSectionLite data={{name: 'Topic Lists'}}/>
          <TopicListModule selectTopic = {handleTopicSelect} handleRefresh = {handleRefresh} />
          
          
        </>,
      foot: <> <WebbDateTime /> </>,
    }}
    main=   
      {{     
        data: 
          <> 
            {/* navs */}
            <QuerryMessageListModule topic = {topic} refresh={refresh}  loading = {wait} question = {ques} />
            {/* <InputModule topic = {topic}  wait = {handleWait} /> */}
          </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: 'Activity',
        data: 
        <div >
          <div >
            <QuerryActionModule topic={topic} />
            <WebbDividerMedium/> 
          </div>
        </div>,
        foot: <> </>,
      }}  
    />
  </>
    
  )
}







