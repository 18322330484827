// user account
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import { ReadRoomMessage, SaveMediaFile, SendProjectMedia } from "../../services/srvc-chats-realm";
import { toBase64 } from "../../services/srvc-utilities";


export default function RoomMessageModule (props) {

const messagesEndRef = useRef(null)
const fileInputRef = useRef(null);

    const navigate = useNavigate();
    const asset = GetLocalUser()
    const team = GetLocalBusiness()
  // console.log(asset)

  const [loader, setLoader] = useState(true)
  const [refresh, setRefresh] = useState(false)
  
  const [roomid, setRoomid] = useState()
  const [user, setUser] = useState()
  
  const [data, setData] = useState([])

  const [ file, setFile] = useState(null)

  const [memo, setMemo] = useState({text:'', color:''})

  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  useEffect(()=>{
    const fetchdata = async()=>{
      setRoomid(props?.data?.item)
      setUser(props?.data?.user)
    }
  fetchdata()
  },[props])



useEffect(() => {
    setLoader(true)
    scrollToBottom()
    setFile(null)
    if(roomid) {

        const fetchdata = async()=>{
            var res = await ReadRoomMessage({data:{room: roomid ||'', items:50, index:1 }})
            setData(res.data.list)
            // console.log(res)
            setLoader(false)
            
        }
        fetchdata()
        
    }
    else {}
  }, [roomid, props.refresh]);

  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
   }

   const handleIconClick = () => {
    // Trigger a click event on the file input
    fileInputRef.current.click();
  };

  const handleFileChange = async(e) => {
    
    setFile(e.target.files[0] )
    scrollToBottom()

  };

  const SendMedia = async()=>{

    var base64 = await toBase64(file)

    setMemo({text:'Please Wait....', color:'warning'})
    setSubmit(true)
    var datx = {
        name: file.name,
        file:base64,
        topic: '',
        type:'local'
    }
   // console.log(datx)
    var res = await SaveMediaFile({data: datx, srvc:'******'})
    console.log(res)
    if(res.stat) {
        var user2 = props.room.user.find(item=> item.item!= asset.item)
        // console.log(user2)
         var datx = {
             room: props.room.item,
             media: res.data ,
             template:'',
             sender: {name:asset.name, mail:asset.mail, item: asset.item, mmid: asset.mobile},
             receiver: {name:user2.name, mail:user2.mail, item:user2.item,mmid:user2.mmid},
             text: '',
             mode:'text'
         }
        // console.log(datx)
        var res = await SendProjectMedia({data:datx, srvc:'******'})
        if(res.stat){ setMemo({text:'Message Sent', color:'success'}); setTimeout(()=>{ setFile(null); setSubmit(false); setMemo({text:'', color:''}) }, 1000) }
        else {setMemo({text:'Error', color:'danger'})}
    }
    else{
        setMemo({text:'Error: File Not Saved', color:'danger'}) 
        setDone(true)
    }
  }



  return (
  <>

   <div className="p-0 m-0" style={{height: '70vh', overflowY:'scroll'}} >
    

    <div className={roomid ? '':'d-none'} >

      {/* content */}
      <div ref={messagesEndRef} style={{marginBottom:'6em'}} className={''} > 
            
        {data && data.map((item, i) => (
        <div className="" key={i}>
          <p className="text-small m-0">
            <span className="text-bold">
                {item?.sndr?.name || '******'}
            </span>
            <span className="ms-2"></span>
            <span className="">{(new Date(parseInt(item.crts))).toLocaleString()}</span>
            <span className="ms-2"></span>
            <span><i className={`bx bxs-circle text-color-${item?.stat?.seen ? 'success' : 'tint'}`}></i></span>
          </p>
          <p className="m-0">{item.text}</p>
          <div className="mb-3"></div>
        
        </div>
        ))}      
      </div>   

    </div>
    <div ref={messagesEndRef} style={{marginBottom:'6em'}}></div>
   </div> 

    
  </>

  )
}