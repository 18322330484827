// main
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'


import ContentFormat from '../content/webz/content-format-xv'

import WebbIcon from '../content/webx/webb-icon'
import WebbHeader from '../content/webz/webb-header-navs-xv'
import UserAvatar from '../content/webx/user-avatar'

import WebbDividerMedium from "../content/webx/webb-divider-md";
import HeaderSectionLite from "../content/webx/head-section-lite";
import HeaderSectionDark from "../content/webx/head-section-dark";
import WebbDateTime from "../content/webx/webb-datetime";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-local";

import UserActionsModule from "../content/home/user-actions";
import ContactListModule from "../content/home/contact-list";


export default function HomeView() {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx


  const metadata = {
    name: 'Home',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }

  return (
  <>
    <Helmet>
      <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
    </Helmet>

    <ContentFormat 

      name = {metadata.name}
      media = {{ size: 'xtra', show: false, data: <></> }}
      header = {{ 
        header: <>
          <Link to={`${asset.role}/home`}>
            <WebbIcon data={{ color: 'text-color-main', size: 'text-icon-sm' }}/>
          </Link>
        </>,       
        data: <> <WebbHeader /> </> ,
        footer: <><UserAvatar /></>,
      }}

      sidebar = {{
        header: <> <strong className="text-tone">Home</strong> </>,
        data: 
          <> 
            <HeaderSectionLite data={{name: 'Action'}}/>
            <UserActionsModule />
          </>,
        footer: <> <WebbDateTime /> </>,
      }}
      content = {{     
        header: <></>,
        data: 
            <> 
              {/* navs */}
            {/* <ContactListModule /> */}
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>,
        footer: <> </>,
      }}
    actionbar = {{      
        header: 'Activity',
        data: 
        <div >
          <div >
           
            <WebbDividerMedium/> 
          </div>
        </div>,
        footer: <> </>,
      }}  
    />
  </>
    
  )
}







