// init
import axios from "axios";
import * as Realm from 'realm-web';
const base = 'https://ap-southeast-1.aws.data.mongodb-api.com/app/tokenize-minichat-icmlf/endpoint'

// -----------------

const  app = new  Realm.App({ id:"tokenize-minichat-icmlf"});

export const GetUserRoomList = async (item) => {
  
  const basx = base + '/room/lists';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}

  var result;
  var result;
  try {
    result = await axios.post(basx, datx, { headers: head })
    // console.log(result)
    return { code: result.status, ...result.data }
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
  }

}

export const CreateChatRoom = async (item) => {
  
    const basx = base + '/room/create';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  export const ReadRoomMessage = async (item) => {
  
    const basx = base + '/message/read';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  
  export const GetTemplateList = async (item) => {
  
    const basx = base + '/template/list';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  export const MessageAddOutbound = async (item) => {
  
    const basx = base + '/outbound/add';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }


  export const ResetNotificationStatus = async (item) => {
  
    const basx = base + '/reset/notification/stat';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  
  export const BulkRoomCreate = async (item) => {
  
    const basx = base + '/bulk/room/create';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  export const GetRoomNotification = async (item) => {

    const  user = await  app.logIn(Realm.Credentials.apiKey("eHtaTBiryLZCAZnuhIlnQQTUF94MktjrNV7i4FR6yBjKiAemmRUR6NHmJBY67Zqg"));        
    const  mndb =  app.currentUser.mongoClient("mongodb-atlas");
    const  dbse =  mndb.db("trxnmodb9u24cohskemabswz").collection("roomsuz24hdmuav"); 
  
    for await (const docx of dbse.watch())  {
          switch (docx.operationType) {
            case "update": {
              const { documentKey, fullDocument } = docx;
              // console.log(`new: ${documentKey.toString()}`, fullDocument);
              return fullDocument
              break;
            }
           
          }
        }
  
    
  }

  //-------------------------------------- topic related

  export const GetTopicLists = async (item) => {
  
    const basx = base + '/topic/list';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  export const GetQuerryMessageLists = async (item) => {
  
    const basx = base + '/querry/message/list';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  export const AddTopicInformation = async (item) => {
  
    const basx = base + '/add/topic/knowledge';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  export const QuerryAddQNA = async (item) => {
  
    const basx = base + '/querry/message/add';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  
  export const AskQuerry = async (item) => {
  
    const basx = base + '/querry/input';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  export const GetProjectLists = async (item) => {
  
    const basx = base + '/project/list';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }


  export const SendProjectMedia = async (item) => {
  
    const basx = base + '/send/project/media';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  export const SendProjectInfo = async (item) => {
  
    const basx = base + '/send/project/info';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }

  export const SaveMediaFile = async (item) => {
  
    const basx = base + '/media/save';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      // console.log(result)
      return { code: result.status, ...result.data }
    } catch (error) {
      // console.log(error)
      return { code: error.response.status, ...error?.response?.data || {code: '******', data:false} }
    }
  
  }


  export const GetTopicNotification = async (item) => {

    const  user = await  app.logIn(Realm.Credentials.apiKey("eHtaTBiryLZCAZnuhIlnQQTUF94MktjrNV7i4FR6yBjKiAemmRUR6NHmJBY67Zqg"));        
    const  mndb =  app.currentUser.mongoClient("mongodb-atlas");
    const  dbse =  mndb.db("trxnmodb9u24cohskemabswz").collection("topicmn24hdmuav"); 
  
    for await (const docx of dbse.watch())  {
          switch (docx.operationType) {
            case "update": {
              const { documentKey, fullDocument } = docx;
              // console.log(`new: ${documentKey.toString()}`, fullDocument);
              return fullDocument
              break;
            }
           
          }
        }
  
    
  }


  
  