// user account
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import { GetQuerryMessageLists, ReadRoomMessage } from "../../services/srvc-chats-realm";


export default function QuerryMessageListModule (props) {

const messagesEndRef = useRef(null)

const navigate = useNavigate();
const asset = GetLocalUser()
const team = GetLocalBusiness()
  // console.log(asset)
  
const [data, setData] = useState([])
//   const [room, setRoom] = useState()
const [roomid, setRoomid] = useState()
const [loading, setLoading] = useState(true)
const [refresh, setRefresh] = useState(false)
const [messages, setMessages] = useState([])

  

const scrollToBottom = () => {
 messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
}


// console.log(props.topic)


useEffect(() => {
    setLoading(true)
    if(props.topic!= null)
    {
       
        const fetchdata = async()=>{
            // var res = await GetQuerryMessageLists({data:{room:props?.topic?.item ||'', items:50, index:1 }})
            // setMessages(res.data.list)
            // console.log(res)
            setMessages(props.topic.chnk)
            setLoading(false)
            scrollToBottom()
        }
        fetchdata()
       
    }
    
  }, [props.topic, props.refresh]);

  

  //console.log(messages)


  return (
  <>

   <div className="container border rounded bg-white p-0 m-0" style={{height: !props?.topic?.actv ?'90vh':'80vh', overflowY:'scroll'}} >
    
    <div className={props.topic == null ? 'bg-white':'d-none'}> 
        <WebbDividerMedium/>
        <WebbDividerMedium/>
        <WebbDividerMedium/>
        <h5 className="text-center text-secondary fw-bold mt-5">Welcome To MiniCHAT</h5>
        <p className="mt-1 text-center fw-normal text-secondary">Diversify Your Chat Experience </p>
        <div className="text-center ">
        <img className="img-fluid" style={{height:'250px'}} src="https://media1.giphy.com/media/AX74iZpE7KcarJpekW/giphy.gif" />
        </div>
    </div>

    <div className={props.topic==null ? 'd-none':''} >
        <div className="d-flex header-user sticky-top bg-light" style={{height:'50px'}}>
            <div className="me-auto">
                <div className="d-flex p-2">
                    <div className=""><i style={{fontSize:'1rem'}} class={props?.topic?.actv?'text-success bx bxs-circle mt-2':'text-secondary bx bxs-circle mt-2' }></i></div>
                    <div className="">
                    <p className=' fw-bold p-2'>{props?.topic?.name }</p>
                    </div>

                </div>
                
            </div>
            <div className="text-end p-2">
                <div className="d-flex" style={{fontSize:'1.5rem'}}>
                    <div><i  class='bx bxs-phone-call'></i></div>
                    <div><i  class='bx bx-video ms-3' ></i></div>
                    <div><i  class='bx bxs-cog ms-3' ></i></div>
                </div>

            </div>
            
        </div>
        
        <div className={props?.topic?.actv ?'':'d-none' } >
            <div className={loading?'':'d-none'}>
                <div class="container mt-5 text-secondary">
                <div class="text-center">
                    <div class="spinner-border" role="status">
                    </div>
                    <p class="mt-2">Loading...</p>
                </div>
                </div>
            </div>
            {messages && messages.length > 0 && messages.map((item, i) => {
            
                    return(
                        <div className="mb-3 p-2" key={i}>
                        <p className=" m-0 p-0 text-tone ">
                            <img src="https://img.lovepik.com/free-png/20220125/lovepik-boy-avatar-png-image_401704863_wh300.png"  style={{width:'20px', height:'20px', borderRadius:'50%'}} />
                            <small className="mx-2 text-small fw-bold " >Miniland{', '}</small> 
                            <small className='text-small d-none' style={{fontSize:'11px'}}>{new Date().toLocaleDateString()}{', '}{new Date().toLocaleTimeString()}</small>
                        </p>
                        <div className='d-flex'>
                            <div className='me-auto w-75 mx-4'>
                                    <p className=" p-0  mx-1 text-small ">{item}</p>  
                            </div>
                            <div className='text-end'>
                                {/* <small className="mx-2 text-small " >{item.sndr.name}{', '}</small> 
                                <small className='text-small' style={{fontSize:'11px'}}>{new Date(item.crts).toLocaleDateString()}{', '}</small> */}
                            </div>    

                        </div>

                        {/* <div className='d-flex'>
                            <div className='me-auto w-75 mx-4'> 
                                    <p className="mt-1 p-0  mx-1 text-small fw-bold">Answer: <span className=" text-small fw-normal">{item.text.answ}</span></p>  
                            </div>
                            <div className='text-end'>
                                <small className="mx-2 text-small " >{item.recv.name}{', '}</small> 
                                <small className='text-small' style={{fontSize:'11px'}}>{new Date(item.crts).toLocaleDateString()}{', '}</small>
                            </div>    

                        </div> */}
                        
                        </div>
                    )
                    
            }  
            )}

            {/* <div className={props?.loading ? 'mb-1 p-2':'d-none'}>
                <p className=" m-0 p-0 text-tone d-none">
                    <img src="https://img.lovepik.com/free-png/20220125/lovepik-boy-avatar-png-image_401704863_wh300.png"  style={{width:'20px', height:'20px', borderRadius:'50%'}} />
                    <small className="mx-2 text-small fw-bold " >{asset.name}{', '}</small> 
                    <small className='text-small' style={{fontSize:'11px'}}>{new Date().toLocaleDateString()}</small>
                </p>
                <div className='d-flex'>
                    <div className='me-auto w-75 mx-4'>
                        <p className=" p-0  mx-1 text-small fw-bold">Question: {props?.question}</p>  
                    </div>
                    <div className='text-end'>
                        <small className="mx-2 text-small " >{asset.name}{', '}</small> 
                        <small className='text-small' style={{fontSize:'11px'}}>{new Date().toLocaleDateString()}</small>
                    </div>    
                </div>
                <div className="text-small fw-bold text-secondary text-center">
                    <img className="" style={{width:'400px', height:'200px'}} src="https://img.wattpad.com/4e1194e8816a479195906328f9ba3a26581a1aa2/68747470733a2f2f73332e616d617a6f6e6177732e636f6d2f776174747061642d6d656469612d736572766963652f53746f7279496d6167652f34725f334c704a704645684a64673d3d2d36332e313661653336613437626533626639643735393639353238303131302e676966" />
                </div>
            </div> */}
        
            
        </div>   

        <div className={!props?.topic?.actv ?'':'d-none' }>
            <WebbDividerMedium/>
            <WebbDividerMedium/>
            <WebbDividerMedium/>
            <WebbDividerMedium/>
            <p className="text-center text-secondary m-0">Waiting On User's Acceptance</p>
            <p className="text-center text-secondary m-0" text-small>Once user accepts , you will be able to send and recieve messages</p>
        </div>   
        
    </div>
    <div ref={messagesEndRef} style={{marginBottom:'6em'}}></div>
   </div> 

    
  </>

  )
}