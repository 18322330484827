// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import { CreateChatRoom } from "../../services/srvc-chats-realm";



export default function CreateRoomModule () {

  const navigate = useNavigate();
  const asset = GetLocalUser()
  const team = GetLocalBusiness()
  // console.log(asset)
  
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState()
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [mobile, setMobile] = useState('')
  const [item, setItem] = useState(Date.now().toString())
  const [text,setText] = useState('')
  


  const CreateRoom  = async()=>{
    
    setText('Please wait....')
    var datx = {
        sender: {name:asset.name, mail: asset.mail, item: asset.item, mobile: asset.mobile},
        receiver: {name: name, mail: mail, item:item, mobile: mobile},
        role: 'admin'
    }
    // console.log(datx)
    var res = await CreateChatRoom({data:datx, srvc:'******'})
    console.log(res)
    if(res.stat) navigate(`/${asset.role}/chat`)
    else{
        setText('Error: Contact Not Created')
    }

  }


  


  return (
  <>

   <div className="">
    <div className="row" >
        
        <div className=" " >
            <label className="text-small">User Name</label>
            <input value={name} onChange={(e)=>setName(e.target.value)}  className="form-control  text-small mb-3"  />
            <label  className="text-small">User Mail</label>
            <input value={mail} onChange={(e)=>setMail(e.target.value)}   className="form-control  text-small mb-3"  />
            <label className="text-small">User Mobile</label>
            <input value={mobile} onChange={(e)=>setMobile(e.target.value)}   className="form-control  text-small mb-3"  />
            <label className="text-small">User Id</label>
            <input value={item} onChange={(e)=>setItem(e.target.value)}   className="form-control  text-small mb-3"  />

        </div>

        <p className="text-dark fw-bold">{text}</p>

        <div className="d-flex mt-4">
            <div className="me-auto">
                <button onClick={()=>navigate(`/${asset.role}/room`)} className="btn btn-light  text-small rounded-xx">Back</button>
            </div>
            <div className="text-end">
                <button onClick={()=>CreateRoom()} disabled={name=="" || mail=="" || mobile.length != 10} className="btn btn-primary text-small rounded-xx">Create</button>
            </div>

        </div>

    </div>
   </div> 

    
  </>

  )
}