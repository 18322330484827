// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import WebbDividerSmall from "../webx/webb-divider-sm";
import { GetTopicLists, GetTopicNotification } from "../../services/srvc-chats-realm";


export default function TopicListModule (props) {

  const navigate = useNavigate();
  const asset = GetLocalUser()
  const team = GetLocalBusiness()
  // console.log(asset)
  
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([])
  const [topicelected, setTopicSelected] = useState(null)
  const [refresh, setRefresh] = useState(false)


  useEffect(()=>{
    setLoading(true)
    const fetchdata = async()=>{
        var res = await GetTopicLists({data:{}, srvc:'*****'})
        //console.log(res)
        if(res.stat) setData(res.data.list)
        setLoading(false)
    }
    fetchdata()

  },[])


  const handleTopicSelect = async(item)=>{
    // console.log(item)
    setTopicSelected(item)
    props.selectTopic(item)
  }

//   console.log(topicelected)
useEffect(()=>{

  const fetchupdate = async()=>{
    var res = await GetTopicNotification()
    // console.log(res) 
    setRefresh(!refresh) 
    props.handleRefresh(!refresh)   
  }
  fetchupdate()

},[refresh])
  
  if(loading) return <>Loading..</>


  return (
  <>

   <div className="">
    <div className="row" >
        <div className="p-2 rounded ">
            {
               data && data.map((item,i)=>{
                return(
                    <>
                    <div onClick={()=>handleTopicSelect(item)} className=" d-flex p-2 bg-white ">
                        <div className="me-auto">
                            <p className=" fw-bold  ">{item.name}</p>    
                        </div>
                        <div className="text-end">
                            
                        <i class= {topicelected?.item  == item.item ?'bx bxs-circle text-success': 'bx bx-circle'} ></i>
                        </div>
                    </div>
                    </>
                )
               }) 
            }

        </div>
        


    </div>
   </div> 

    
  </>

  )
}