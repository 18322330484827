// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import { CreateChatRoom } from "../../services/srvc-chats-realm";


const list = [
    {name:'Create Room', memo:'Add User As Your Contact', link:'/user/room/create', actv: true},
    {name:'Create Bulk Room', memo:'Upload Excel File To Add Your Contact', link:'/user/room/create/bulk', actv: true}
]


export default function RoomActionModule () {

  const navigate = useNavigate();
  const asset = GetLocalUser()
  const team = GetLocalBusiness()
  // console.log(asset)
  
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState()

    


  return (
  <>

   <div className="">
    <div className="row" >
        <div className="p-2">  
            {
                list.map(item=>{
                    return(
                        <>
                            <div onClick={()=> navigate(item.link)} className={item.actv?" border mb-2 rounded bg-white p-2 text-small d-flex cursor ":'d-none'}>
                                <div className="me-auto">
                                    <h6 className="m-0 " style={{fontSize:'14px'}}>{item.name}</h6>
                                    <p className="text-secondary">{item.memo}</p>
                                </div>
                                <div className="text-end">
                                <i class='bx bx-chevrons-right mt-2 text-primary' style={{fontSize:'26px'}}></i>
                                </div>
                            </div>
                        </>
                    )
                })
            } 


        </div>

    </div>
   </div> 

    
  </>

  )
}