// user account
import {  useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import WebbDividerSmall from "../webx/webb-divider-sm";
import { AddTopicInformation, MessageAddOutbound, QuerryAddQNA } from "../../services/srvc-chats-realm";


export default function AddQuestionAnswerModule () {


const {id} = useParams()

const navigate = useNavigate();
const asset = GetLocalUser()
const team = GetLocalBusiness()
  // console.log(asset)
  
const [data, setData] = useState()
const [loading, setLoading] = useState(false)
const [input, setInput] = useState('')
const [ques, setQues] = useState('')
const [memo, setMemo] = useState('')






const AddQNAToRoom = async()=>{
    setLoading(true)
    setMemo(true)
    // console.log(user2)
     var datx = {
         room: id,
         sender: {name:'Anonymous', mail:'', item: '', mobile: ''},
         receiver: {name:asset.name, mail:asset.mail, item:asset.item,mobile:asset.mobile},
         text: {ques: ques, answ: input},
     }
     console.log(datx)
     var res = await QuerryAddQNA({data:datx, srvc:'******'})
     if(res.stat) {
        navigate("/user/querry")
     }
     else{
        setMemo('Error')
        setLoading(false)
     }

}

  


  return (
  <>
    <WebbDividerSmall />
   <div className=""  >
    <div className={' p-2'}>  
        <label className="mb-2">Question</label>
        <input value={ques} onChange={(e)=>setQues(e.target.value)} className="form-control text-small mb-3 p-2" />

        <label className="mb-2">Answer</label>
        <textarea className="form-control text-small" style={{height:'50vh'}} value={input} onChange={(e)=>setInput(e.target.value)} placeholder="Add Answer" >
        </textarea>
        <button  onClick={()=>AddQNAToRoom()} disabled={loading || input=='' || ques==''} className=" float-end btn btn-primary rounded-xx text-small mt-3 w-25">submit</button>   
        <p className="text-small mt-2 text-secondary">{memo}</p>
    </div>
   </div> 

    
  </>

  )
}