// user account
import {  useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import WebbDividerSmall from "../webx/webb-divider-sm";
import { MessageAddOutbound } from "../../services/srvc-chats-realm";


export default function InputModule (props) {

  const asset = GetLocalUser()
  const team = GetLocalBusiness()

  const navigate = useNavigate();
  
  const [roomid, setRoomid] = useState()
  const [user, setUser] = useState()

  const [data, setData] = useState()
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState()

  useEffect(()=>{
    const fetchdata = async()=>{
      setRoomid(props?.data?.item)
      setUser(props?.data?.user)
    }
  fetchdata()
  },[props])



  const AddMessageIntoRoom = async()=>{

     var datx = {
         room: roomid,
         template:'',
         sender: {name: asset.name, mail:asset.mail, item: asset.item, mobile: asset.mobile},
         receiver: {name:user?.name, mail: user?.mail, item: user?.item, mobile: user?.mmid },
         text: input,
         mode:'text'
     }
     console.log(datx)
     var res = await MessageAddOutbound({data:datx, srvc:'******'})


  }

const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        // console.log(event.target.value)
      setInput(event.target.value);
      AddMessageIntoRoom()
      setInput('')
    }
}




  


  return (
  <>
    <WebbDividerSmall />
   <div className={roomid==null?'d-none':"container border rounded bg-white"} style={{height:'8vh'}} >
    <div className={'row'}>     
    <input  onKeyDown={(e)=>handleKeyDown(e)} value={input} onChange={(e)=>setInput(e.target.value)} style={{height:'3.6rem'}} className='form-control ' type='text' placeholder='Say Something' />

    </div>
   </div> 

    
  </>

  )
}