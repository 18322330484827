// main
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ContentFormat from '../content/webz/content-format-xv'

import WebbIcon from '../content/webx/webb-icon'
import WebbHeader from '../content/webz/webb-header-navs-xv'
import UserAvatar from '../content/webx/user-avatar'

import WebbDividerMedium from "../content/webx/webb-divider-md";
import HeaderSectionLite from "../content/webx/head-section-lite";
import HeaderSectionDark from "../content/webx/head-section-dark";
import WebbDateTime from "../content/webx/webb-datetime";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-local";

import CreateRoomModule from "../content/rooms/create-room";
import RoomActionModule from "../content/rooms/room-actions";
import ContactListModule from "../content/home/contact-list";


import NetworkListSmallModule from '../content/network/network-list-sm'
import RoomMessageModule from '../content/chat/room-messages'
import InputModule from "../content/chat/input";

export default function RoomView() {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const metadata = {
    name: 'Rooms',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }

  const [sort, setSort] = useState('contracts');
//const [search, setSearch] = useState();
  const [data, setData] = useState([])
  const [selected, setSelected] = useState({})
  const [srch, setSearch] = useState('')
  const [room ,setRoom] = useState()
  const [contact, setContact] = useState([])

  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [roomselected, setRoomSelected] = useState(null)
  const history = useNavigate()

  
  const handleRoomSelect = async(item)=>{
    console.log(item)
    setRoomSelected(item)
    setRefresh(!refresh)
  }

  const handleRefresh = async(item)=>{
    setRefresh(!refresh)
  }



  return (
  <>
    <ContentFormat
      
      name = {metadata.name}
      media = {{ size: 'xtra', show: false, data: <></> }}
      header = {{ 
        header: <>
          <Link to={`${asset.role}/home`}>
            <WebbIcon data={{ color: 'text-color-main', size: 'text-icon-sm' }}/>
          </Link>
        </>,       
        data: <> <WebbHeader /> </> ,
        footer: <><UserAvatar /></>,
      }}

      sidebar = {{ 
        header: <><p className="text-normal text-bold m-0">{metadata.name}</p></>,       
        data: <>
          <div className='border-bottom'></div>
          <NetworkListSmallModule room = {handleRoomSelect}/>
        </> ,
        footer: <><WebbDateTime /></>,
      }}   

      content = {{ 
        header: <>
          <p className="m-0">{roomselected?.user?.name || ''}</p>
        </>,       
        data: <>
          <RoomMessageModule  data={roomselected} refresh = {refresh} />
          <InputModule data={roomselected} />

        </>
      }}
      actionbar = {{ 
        header: <>Your Actions</>,       
        data: <>
          <RoomActionModule />
        </> ,
        footer: <></>,
      }} 
    />
  </>
    
  )
}







