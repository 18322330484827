// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import { CreateChatRoom, SaveMediaFile } from "../../services/srvc-chats-realm";
import { toBase64 } from "../../services/srvc-utilities";





export default function QuerryActionModule (props) {

  const navigate = useNavigate();
  const asset = GetLocalUser()
  const team = GetLocalBusiness()
  // console.log(asset)
  
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState()
  const [memo, setMemo] = useState({text:'', color:''})
  const [media, setMedia] = useState(false)
  const [file, setFile] = useState(null)
  const [name, setName] = useState('')
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)

  const list = [
    {name:'Add Knowledebase', memo:'Add Information to topic', link:`/user/add/knowledge/${props?.topic?.item}`, actv: true},
    {name:'Add QNA', memo:'Add Question-Answering related To Topic', link:`/user/add/qna/${props?.topic?.item}`, actv: false},
    {name:'Add Media & Docs', memo:'Add media and documents to Project', link:``, actv: true}
]

useEffect(()=>{
    setMemo('')
},[props.topic])

const handleClick= async(item)=>{
    if(props?.topic && item.link != '')
    {
        navigate(item.link)
    }
    if(props?.topic && item.link == '')
    {
        setMedia(true)
    }
    else{
        setMemo('Please Select Topic Then Procced To Add Knowledge Base ')
    }
}

const SaveMedia = async()=>{
    setMemo({text:'Please Wait....', color:'warning'})
    setSubmit(true)
    var base64 = await toBase64(file)
    var datx = {
        name:name,
        file:base64,
        topic: props.topic.item,
        type:'local'
    }
    console.log(datx)
    var res = await SaveMediaFile({data: datx, srvc:'******'})
    if(res.stat) {
        setMemo({text:'File Saved', color:'success'})
        setDone(true)
    }
    else{
        setMemo({text:'Error: File Not Saved', color:'danger'}) 
        setDone(true)
    }
}

const RessetState=async()=>{
    setName('')
    setFile(null)
    setSubmit(false)
    setMemo({text:'', color:''})
    setDone(false)
}
    


  return (
  <>

   <div className="">
    <div className="row" >
        <div className="p-2">  
            {
                list.map(item=>{
                    return(
                        <>
                            <div  onClick={()=> handleClick(item)} className={item.actv?" border mb-2 rounded bg-white p-2 text-small d-flex cursor ":'d-none'}>
                                <div className="me-auto">
                                    <h6 className="m-0 " style={{fontSize:'14px'}}>{item.name}</h6>
                                    <p className="text-secondary">{item.memo}</p>
                                </div>
                                <div className="text-end">
                                <i class='bx bx-chevrons-right mt-2 text-primary' style={{fontSize:'26px'}}></i>
                                </div>
                            </div>
                           
                        </>
                    )
                })
            } 


        </div>
        <p className={`text-${memo.color} text-small`}>{memo.text}</p>

        <div className={media ? 'bg-white mb-3' : 'd-none'}>
            <div className="p-0">
                <label className="text-small mt-3 mb-1">Name</label>
                <input disabled={submit} type="text" value={name} className="form-control" onChange={(e)=> setName(e.target.value)} />

                <label className="text-small mt-3 mb-1">Select Files</label>
                <input disabled={submit} type="file" className="form-control" onChange={(e)=> setFile(e.target.files[0])} />
                <div className="d-flex">
                   <div className="me-auto"><button disabled={name=='' || file==null || submit} onClick={()=>SaveMedia()} className=" btn btn-primary mt-4 mb-3 rounded-xx">Save</button></div>
                    <div onClick={()=>RessetState()} className={done?'text-end':'d-none'}><button className="btn btn-light text-small rounded-xx mt-4 mb-3">Add More</button></div>
                </div>
            </div>

        </div>

    </div>
   </div> 

    
  </>

  )
}