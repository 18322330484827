// network
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import NavsButtonBack from '../webx/navs-button-back'
import NavsButtonNext from '../webx/navs-button-next'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { GetUserRoomList } from "../../services/srvc-chats-realm";

export default function NetworkListSmallModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()

  const [user, setUser] = useState({
    name: '******', mail: '******', item: '******'
  })
  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        // console.log ('transfers')

        const result = await GetUserRoomList({data: {
          user: asset.item, index: index, items: items,
          filters:{ name: props.search }
        }})
        console.log (result)

        if (result.stat ) {
          const networkx = Array.from (result.data.list, x=> { return {
            user: x.user.find(z => z.item !== asset.item),
            item: x.item
          }})
          console.log (networkx)
          setData(networkx)
          
          const count = result.data.count
        
          setTotal(count)

          setText(`${((index-1) * items +1)} - ${index * items < count ? index * items : count} of ${count}`)
        }
        setLoader(false)
      }
      fetchData()
    } else {}
  }, [props.search, index, items])
  

  const handleClick = async (item) => {
    var userx = data.find(x => x.item == item)
    // console.log(userx.user)
    props.room(userx)
    setUser(userx.user)

    // navigate(`/${asset.role}/ux/${item}`)
  }

  if (loader)
    return ( <>
      <div className='p-3 back-color-wite rounded border align-middle' style={{}}>
        <span className='align-middle text-lead'>
          <i className='bx bxs-info-circle text-color-wait'></i>
        </span>
        <span className='ms-1 text-color-tone'>Please Wait...</span>
      </div>
    </>
  )

  if (!loader && (!data || data.length === 0))
    return (<>
      <div className='p-3 back-color-wite rounded border align-middle' style={{}}>
        <span className='align-middle text-lead'>
          <i className='bx bxs-info-circle text-color-tint'></i>
        </span>
        <span className='ms-1 text-color-tone'>No Contact List</span>
      </div>
    </>
  )

  return (
    <>
      <div className=''>
        {/* header */}

        {/* data */}
        {data && data.map((item, i) => (
        <div className="cursor hidark" key={i} 
          style={{backgroundColor: `${user?.item == item?.user?.item ? '#BBDEFB': ''}`}}
          onClick={() => handleClick(item?.item)}>
          <div className="d-flex p-1 px-0 mx-3">

            <div className="d-flex" style={{width: '70%'}}>  
              
              <div className="mt-1 me-2">
                <span className="align-middle">
                  <Jazzicon diameter={12} seed={jsNumberForAddress(item?.user?.item || Date.now().toString())} /> 
                </span>
              </div>

              <div className="">
                <p className="m-0 mt-1 text-sm">
                  <span className={user?.item == item?.user?.item ? 'text-bold':''}>{item?.user?.name}</span>
                </p>
                <p className="m-0 text-small text-sm d-none">
                  <span className="">{item?.user?.mail}</span>
                </p>            
              </div>

            </div>

            <div className="ms-auto text-end text-sm d-none">         
              <p className="m-0">
                <i className="bx bxs-circle"></i>
              </p>
            </div>

          </div>
          <div className={i < data.length-1 ? 'border-bottom': ''}></div>
        </div>
      ))}
      </div>


    </>
  )
}
