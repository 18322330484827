// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import { BulkRoomCreate } from "../../services/srvc-chats-realm";



export default function CreateBulkRoomModule () {

  const navigate = useNavigate();
  const asset = GetLocalUser()
  const team = GetLocalBusiness()
  // console.log(asset)
  
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState()
  const [file, setFile] = useState()
  const [json, setJSON] = useState([])
  const [text,setText] = useState('Excel file Should Contain Name, mail, mobile and user-id')
  const [objectlist, setObjectList] = useState([])
  const [showsubmit, setShowSubmit] = useState(false)
  const [ done, setDone] = useState(false)

  const CreateRoom  = async()=>{
    
    setText('Please wait....')
    var datx = {list: objectlist}
    console.log(datx)
    var res = await BulkRoomCreate({data:datx, srvc:'******'})
    console.log(res)
    if(res.stat){ 
        setText(`Task submitted with Id - ${res.data}`)
        setDone(true)
    } //navigate(`/${asset.role}/chat`)
    else{
        setText('Error: server error')
    }

  }

  useEffect(()=>{
    var obj =[]
    if(json.length>0)
    {
        for(var i=0; i< json.length ; i++)
        {
            var item ={
                sndr: {name: asset.name, mail:asset.mail, mobile: asset.mobile, item: asset.item },
                recv: {name: json[i].name, mail:json[i].mail, mobile: json[i].mobile.toString(), item: json[i]?.item || Date.now().toString() },
                role: 'admin',
            }
            obj.push(item)
        }

        setObjectList(obj)
        setShowSubmit(true)
        // console.log(obj)
    }

  },[json])

  const prepareJSON = async()=>{

    if (file) {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          setJSON(jsonData)
        //   console.log('Converted JSON:', jsonData);
        };  
        reader.readAsBinaryString(file);
      }

  }




  


  return (
  <>

   <div className="">
    <div className="row" >
        
        <div className=" mt-2" >
           <label className="textsmall">Chose Excel File</label>
           <input className="form-control mt-2" type="file" accept=".xls, .xlsx" onChange={(e)=> {setFile(e.target.files[0]);setShowSubmit(false); setObjectList([])}} />
            
        </div>
        <div className={objectlist.length>0 ? 'p-2 m-0':'d-none'}>
            <div className="row text-small mt-3 p-2">
                                <div className="col-4">
                                    Name
                                </div>
                                <div className="col-4">
                                    Mail
                                </div>
                                <div className="col-4 text-end m-0">
                                    Mobile
                                </div>

            </div>
            <div className="bg-white border rounded p-3 ">
                {
                    objectlist.length> 0 && objectlist.map(item=>{
                        return(
                        <>
                            <div className="row text-small">
                                <div className="col-4">
                                    {item.recv.name}
                                </div>
                                <div className="col-4">
                                    {item.recv.mail}
                                </div>
                                <div className="col-4 text-end">
                                    {item.recv.mobile}
                                </div>

                            </div>
                        </>)
                    })
                }

            </div>
        </div>

        <p className="text-dark text-small mt-2">{text}</p>

        <div className="d-flex mt-4">
            <div className="me-auto ">
                <button className="btn btn-light d-none  text-small rounded-xx">Back</button>
            </div>
            <div className={objectlist.length>0 ?"d-none":'text-end'}>
                <button  onClick={()=>prepareJSON()} disabled={!file} className="btn btn-primary text-small rounded-xx">Create</button>
            </div>
            <div className={showsubmit ?"":'d-none'}>
                <button  onClick={()=>CreateRoom()} disabled={objectlist.length==0 || done} className="btn btn-primary text-small rounded-xx">Submit</button>
            </div>

        </div>

    </div>
   </div> 

    
  </>

  )
}