// user account
import {  useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import WebbDividerSmall from "../webx/webb-divider-sm";
import { AddTopicInformation, MessageAddOutbound } from "../../services/srvc-chats-realm";


export default function AddKnowledgeBaseModule () {


const {id} = useParams()

const navigate = useNavigate();
const asset = GetLocalUser()
const team = GetLocalBusiness()
  // console.log(asset)
  
const [data, setData] = useState()
const [loading, setLoading] = useState(false)
const [input, setInput] = useState('')
const [memo, setMemo] = useState('')






const AddInfoToTopic = async()=>{
    setMemo("Please Wait.....")
    setLoading(true)
     var datx = {
         item:id,
         text:input
     }
     console.log(datx)
     var res = await AddTopicInformation({data:datx, srvc:'******'})
     if(res.stat){
        navigate('/user/querry')
     }
    else{
        setLoading(false)
        setMemo('Error')
    }


}

  


  return (
  <>
    <WebbDividerSmall />
   <div className=""  >
    <div className={''}>  
        <textarea className="form-control text-small" style={{height:'50vh'}} value={input} onChange={(e)=>setInput(e.target.value)} placeholder="Add Information......." >
        </textarea>
        <button onClick={()=>AddInfoToTopic()} disabled={loading || input==''} className=" float-end btn btn-primary rounded-xx text-small mt-3 w-25">submit</button>   
        <p className="text-small mt-2 text-secondary">{memo}</p>
    </div>
   </div> 

    
  </>

  )
}