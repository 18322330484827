// routes
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

// views - main
import Main from "../views.xz/main-xz";

// views - assets

// views - home
import HomeUser from "../views.home/home-user";

// views - rooms
import ChatView from "../views.chat/chat-view-home";
import RoomView from "../views.rooms/rooms";
import BulkRoomCreateView from "../views.rooms/bulk-room-create";
import RoomCreate from "../views.rooms/room-create";
import QuerryHomeView from "../views-querry.js/querry-home";
import TopicInfoCreate from "../views-querry.js/topic-info-add";
import TopicQnACreate from "../views-querry.js/topic-qna-add";


// views - user
import UserDetails from "../views.user/user-details";


// // views - auth
import AuthMailCode from "../views.auth/auth-mail-code";
import AuthNext from "../views.auth/auth-next";
import AuthSessionX from "../views.auth/auth-session-x";




const routes = [

  { route:'/', content: <Main />, auth:false },

  // user
  { route:'/user/home', content: <HomeUser />, auth:false },
  { route:'/team/home', content: <HomeUser />, auth:false },



  // auth
  { route:'/auth', content: <AuthMailCode />, auth: false },
  { route:'/auth/next', content: <AuthNext />, auth: true },
  { route:'/auth/x', content: <AuthSessionX />, auth: true },
  
  // chat

  { route:'/user/chat', content: <ChatView />, auth: false },
  { route:'/user/room', content: <RoomView />, auth: false },
  { route:'/user/room/create', content: <RoomCreate />, auth: false },
  { route:'/user/room/create/bulk', content: <BulkRoomCreateView />, auth: false },

  // querry
  { route:'/user/querry', content: <QuerryHomeView />, auth: false },
  { route:'/user/add/knowledge/:id', content: <TopicInfoCreate />, auth: false },
  { route:'/user/add/qna/:id', content: <TopicQnACreate />, auth: false },
  

]


export default function RouteX() {

  const { user } = useAuth();
  // console.log (user)

  return (
    <Routes>
      {routes.map ((item,i)=>(item.auth
        ? <Route key={i} path={item.route} element={!user ? <Navigate to='/' replace /> : item.content} />
        : <Route key={i} path={item.route} element={item.content} />
      ))}
    </Routes>
  );
}