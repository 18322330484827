// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local';
import WebbDividerSmall from "../webx/webb-divider-sm";
import { SearchUser } from "../../services/srvc-user-realm";
import { CreateChatRoom, GetRoomNotification, GetUserRoomList, ResetNotificationStatus } from "../../services/srvc-chats-realm";


export default function ChatRoomListModule (props) {

  const navigate = useNavigate();
  const asset = GetLocalUser()
  const team = GetLocalBusiness()
  // console.log(asset)
  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([])
  const [key, setKey] = useState('')
  const [roomlist, setRoomlist] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [selected, setSelected] = useState(null)


  useEffect(()=>{
    // setLoading(true)
    const fetchdata = async()=>{
        var res = await GetUserRoomList({data:{user:asset.item}})
        //console.log(res)
        setRoomlist(res.data.list)
        setLoading(false)
    }

    fetchdata()
  },[refresh])


  const FindUser = async()=>{
    setLoading(true)
    var datx = {filters:{search:key }}
    var res = await SearchUser({data:datx})
   // console.log(res)
    setData(res.data.list)
    setLoading(false)

  }

  const CreateRoom  = async(item)=>{
    
    setLoading(true)
    var datx = {
        sender: {name:asset.name, mail: asset.mail, item: asset.item, mobile: asset.mobile},
        receiver: {name: item.name, mail: item.mail, item:item.item, mobile: item.mobile},
        role: 'admin'
    }
    // console.log(datx)
    var res = await CreateChatRoom({data:datx, srvc:'******'})
    console.log(res)
    if(res.stat) setRefresh(!refresh)
    setData([])

  }

  const handleRoomSelection = async(item)=>{
    setSelected(item)
    var res = await ResetNotificationStatus({data:{item:item.item, user:asset.item}, srvc:'******'})
    props.selectroom(item)
  }

  useEffect(()=>{

    const fetchupdate = async()=>{
      var res = await GetRoomNotification()
      // console.log(res) 
      setRefresh(!refresh) 
      props.handleRefresh(!refresh)   
    }
    fetchupdate()

  },[refresh])
  



  return (
  <>

   <div className="container-fluid"  >
    <div className="row">
      <p className="fw-bold text-secondary">contact : {roomlist.length}</p>
        <div className=" col-12 d-flex p-2">
            <div className="me-auto ">
                <input value={key} onChange={(e)=>{setKey(e.target.value);setData([]) }} className="form-control text-small " placeholder="search user" />
            </div>
            <div className="text-end">
                <button onClick={()=>FindUser()} className="btn btn-primary rounded-xx text-small " style={{width:'5rem', height:'2rem'}}>find</button>
                
            </div>
             
        </div>
        <div className="">
                    {
                        data.map((item,i)=>{
                            return(
                                <>
                                    <div className=" bg-light p-2 mb-2 mt-2 border rounded">
                                        <div className="d-flex">
                                            <div className="me-auto">
                                                <p className="text-small m-0">{item.name}</p>
                                                <p className="text-small m-0">{item.mobile}</p>
                                                <p className="text-small m-0">{item.mail}</p>
                                            </div>
                                            <div className="text-end">
                                                <button onClick={()=>CreateRoom(item)} className="btn btn-outline-primary rounded-xx " style={{width:'3rem', height:'3rem'}}>+</button>
                                            </div>

                                        </div>
                                        
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
        <div className={loading?'':'d-none'}>
            <div class="container mt-5 text-secondary">
            <div class="text-center">
                <div class="spinner-border" role="status">
                </div>
                <p class="mt-2">Loading...</p>
            </div>
            </div>
        </div>

        <div className={loading?'d-none':''}>
            <div className={roomlist.length==0?'':'d-none'}>
                <p className="text-center text-secondary">No Contact Found</p>
            </div>

            <div className={roomlist.length>0?' border rounded bg-white':'d-none'}>
            {
                roomlist && roomlist.length>0 && roomlist.map((item,i)=>{
                    return(
                      <>
                        <div key={i} onClick={()=>handleRoomSelection(item)} className={`d-flex m-2 py-2  ${selected?.item == item.item ? 'border  text-dark m-2 py-2 border-primary rounded   ':'' }` } style={{cursor:'pointer' }}>
                            <div className="bg-warning " style={{borderRadius:'50%' , width:'2.5rem', height:'2.5rem', }}>
                            </div>
                            <div className="ms-2 m-0" style={{marginTop:'0.37rem'}}>
                              {
                                item.user.map((user)=>{
                                  // console.log(user)
                                  return <p style={{whiteSpace:'nowrap', overflow:'hidden',  textOverflow:'ellipsis', width:'auto' }} className={` ${user.item === asset.item?'d-none':'mx-2  my-auto text-small ms-2 fw-bold  m-0'} `}>{user.name }</p>
                                })
                              }
                              <div className="d-flex ms-2 ">
                                <div><p style={{whiteSpace:'nowrap', overflow:'hidden',  textOverflow:'ellipsis', maxWidth:'200px', width:'120px' }}  className="text-small ">{item.lastMessage}</p></div>
                                <div className={item.seenPendingBy != asset.item && parseInt(item.notf)==0 ? 'text-success':'d-none' }>
                                  <div className=""  > <i className="bi bi-check2-all text-primary" style={{fontSize:'1.2rem'}}  ></i> </div>
                                </div> 
                              </div>    
                            </div>
                            <div  className={item.seenPendingBy === asset.item && parseInt(item.notf)>0? 'text-success  ':'d-none' }>
                               <div className="bg-success " style={{borderRadius:'50%', width:'2rem', height:'2rem'}} > <p className="text-light text-center p-1 text-small">{item.notf}</p> </div>
                            </div>
                        </div>
                      
                      </>
                    )
                })
            }
            </div>
            
        </div>
       
        


    </div>
   </div> 

    
  </>

  )
}